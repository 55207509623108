import { useState, useEffect } from "react";
import { SWRConfig } from 'swr';
import { Routes, Route, BrowserRouter } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import Connections from "./pages/connections";
import { apiFetch } from "./lib/fetch";
import Header from "./components/header";
import ProtectedRoute from "./components/protected-route";

function App() {
  const [user, setUser] = useState(null);
  const [userLoading, setUserLoading] = useState(true);

  function pr(path, Comp) {
    return (<Route
      path={path}
      element={
        <ProtectedRoute user={user} userLoading={userLoading}><Comp user={user}/></ProtectedRoute>
      }>
    </Route>);
  }

  useEffect(() => {
    async function checkUser() {
      try {
        const me = await apiFetch("/users/me");
        if (me) {
          setUser(me);
        }
      } catch (e) {
        console.log(e);
      }
      setUserLoading(false);
    }
    checkUser();
  }, []);

  return (
    <BrowserRouter>
      <SWRConfig
        value={{
          refreshInterval: 0,
          fetcher: apiFetch,
        }}
      >
        <div>
          <Header user={user}/>
          <Routes>
            <Route path='/' element={<Home user={user}/>} />
            <Route path='/login' element={<Login user={user}/>} />
            {pr('/dashboard', Connections)}
            {pr('/devices', Connections)}
            {pr('/app', Connections)}
            {pr('/connections', Connections)}
          </Routes>
        </div>
      </SWRConfig>
    </BrowserRouter>
  );
}
export default App;