import { omitBy, isUndefined, startsWith } from 'lodash';
import config from '../config';

let authToken = null;
if (config.useJWT) {
  authToken = window.localStorage.getItem('idToken');
}

export function setToken(newToken) {
  if (config.useJWT) {
    authToken = newToken;
    window.localStorage.setItem('idToken', authToken);
  }
  if (!newToken) {
    window.localStorage.removeItem('idToken');
  }
}

function getContent(res) {
  const contentType = res.headers.get('content-type');
  if (startsWith(contentType, 'application/json')) {
    return res.json();
  }
  if (startsWith(contentType, 'text')) {
    return res.text();
  }
  return res.blob();
}

export default function handledFetch(path, options) {
  return fetch(path, options)
    .then(async (res) => {
      if (res.status >= 400) {
        const content = await getContent(res);
        const err = new Error(content?.message || content?.error || 'Bad response from server');
        err.status = res.status;
        err.content = content;
        throw err;
      }
      return res;
    });
}

export function apiFetch(path, options = {}) {
  let qs = '';
  const isFormData = options.body instanceof FormData;
  if (typeof options.body === 'object' && !isFormData) {
    options.body = JSON.stringify(options.body);
  }
  if (options.query) {
    const query = omitBy(options.query, isUndefined);
    if (Object.keys(query).length) {
      qs = `?${(new URLSearchParams(query)).toString()}`;
    }
  }
  Object.assign(options, { credentials: 'include' });
  if (!isFormData) {
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    };
  }
  if (authToken) {
    options.headers = options.headers || {};
    Object.assign(options.headers, { Authorization: authToken });
  }

  return handledFetch(`${config.API_URL}${path}${qs}`, options)
    .then(getContent);
}

apiFetch.post = (path, body) => {
  return apiFetch(path, { method: 'POST', body });
};

apiFetch.put = (path, body) => {
  return apiFetch(path, { method: 'PUT', body });
};

apiFetch.del = (path, body = {}) => {
  return apiFetch(path, { method: 'DELETE', body });
};
