import React, { useState, useEffect } from 'react';
import {
  Card, CardHeader, Typography,
} from '@mui/material';
import { get } from 'lodash';

import { colors } from '../lib/styles';
import { GoogleButton, MicrosoftButton, startOauth } from '../lib/oAuth';

const styles = {
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    padding: '10px',
  },
  forgotPassword: {
    color: colors.primaryText,
    fontFamily: [
      'Maven Pro, Roboto, sans-serif, Circular Std, sans-serif',
    ].join(','),
  },
  tintedBackground: {
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
    backgroundColor: colors.blackOpacity,
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
    margin: '10px',
  },
  form: {
    margin: 'auto',
    padding: '35px 50px 50px',
  },
  loginField: {
    margin: '10px',
  },
  loginBrand: {
    height: '18px',
    width: '18px',
    marginRight: '15px',
  },
  loginBrandText: {
    fontSize: '1.2em',
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
  },
  orSpacer: {
    margin: '10px',
    fontSize: '1.2em',
    color: colors.white,
  },
};

const Login = (props) => {
  const { user } = props;
  const [loginError] = useState();
  const [oauthProviders, setOauthProviders] = useState(null);

  useEffect(() => {
    console.log('Login useEffect');
    async function start() {
      const result = await startOauth('login');
      setOauthProviders(result);
    }
    start();
  }, []);

  const propErrors = {};
  let errorMsg = '';
  if (user?.authError && user?.authError.details) {
    user.authError.details.forEach((detail) => {
      propErrors[detail.path] = detail.message;
    });
  }
  const err = user?.authError || user?.oauthError || loginError;
  if (err) {
    // eslint-disable-next-line
    errorMsg = get(err, 'content.message') || get(err, 'content.error') || err.message;
  }
  return (
    <div style={styles.container} >
      <Card style={styles.tintedBackground}>
        <CardHeader style={styles.cardHeader} title={<Typography variant="h5">Sign In</Typography>} />
        <div style={styles.errorText}>{errorMsg}</div>
        <div style={styles.form}>
          {oauthProviders && Object.keys(oauthProviders).length ? (
            <div style={{ marginBottom: '20px' }}>
              <div>
                {oauthProviders.google ? (
                  <GoogleButton
                    authurl={oauthProviders?.google?.url}
                    style={{ display: oauthProviders && oauthProviders.google ? '' : 'none' }}
                  />
                ) : ''}
                {oauthProviders.microsoft ? (
                  <MicrosoftButton
                    authurl={oauthProviders?.microsoft?.url}
                    style={{ display: oauthProviders && oauthProviders.microsoft ? '' : 'none' }}
                  />
                ) : ''}
              </div>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
};

export default Login;
