import Login from "./login";

const ProtectedRoute = ({ user, children, userLoading }) => {
  if (userLoading) {
    return <div>Loading...</div>;
  }
  if (!user) {
    return <Login />;
  }
  return children;
};



export default ProtectedRoute;