import React from 'react';
import Login from '../components/login';

const LoginPage = (props) => {
  return (
    <div>
      <Login {...props} />
    </div>
  );
};

export default LoginPage;