const { Link } = require("react-router-dom");

const { apiFetch } = require("../lib/fetch");

const Header = (props) => {
  const { user } = props;
  return (
    <nav style={{ textAlign: "center", marginTop: "20px" }}>
      <Link to="/" style={{ padding: "10px" }}>
        Home
      </Link>
      <Link to="/connections" style={{ padding: "10px" }}>
        Connections
      </Link>
      {!user ? (
        <Link to="/login" style={{ padding: "10px" }}>
          Login
        </Link>
      ) : (
        <button
          onClick={async () => {
            try{
              await apiFetch('/users/logout');
              window.location.href = "/";
            } catch(e) {
              console.log(e);
            }
         }}
        >Logout</button>
      )}
    </nav>
  );
};

export default Header;