export function isSerivceRep(user) {
  if (!user) return false;
  if (!user.scope) return false;
  return user.scope.includes('SERVICE_REP');
}

export function isAdmin(user) {
  if (!user) return false;
  if (!user.scope) return false;
  return user.scope.includes('ADMIN');
}

/* eslint-disable max-len */

export const formatSSN = (value) => {
  if (value?.length > 11) return value.slice(0, 11);
  const ssn = (value || '').replace(/\D/g, '');
  return ssn.replace(/(\d{0,3})(\d{0,2})(\d{0,4})/, (_, p1, p2, p3) => {
    let result = '';
    if (p1) result += p1;
    if (p2) result += `-${p2}`;
    if (p3) result += `-${p3}`;
    return result;
  });
};

export const formatPhoneNumber = (value) => (value || '').replace(/[^\d]/g, '').replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');

export const cleanNumber = (num) => (num || '').replace(/[^0-9.]/g, '');

export const formatCurrency = (dollars) => {
  if (!dollars) return '';
  let money = dollars;

  money = parseFloat(cleanNumber(money)).toFixed(2);
  if (money >= 1000000000) {
    return '999,999,999.99';
  }
  return money.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const formatZipCode = (value) => (value || '').replace(/[^\d]/g, '').replace(/^(\d{5})(\d{4})?$/, (_, p1, p2) => (p2 ? `${p1}-${p2}` : p1));

const validChars = 'abcdefghijklmnopqrstuvwxyz0123456789-'.split('');
export const cleanDeviceName = (input) => {
  const raw = input || '';
  const chars = raw.toLowerCase().split('');
  const clean = chars.reduce((result, char) => {
    if (validChars.includes(char)) {
      return `${result}${char}`;
    }
    return result;
  }, '');
  return clean;
}