import React, { useState } from 'react';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import CopyIcon from '@mui/icons-material/CopyAll';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import { TextField } from '../lib/common';
import { cleanDeviceName } from '../lib/util';
import Avatar from '../components/robo-avatar';
import config from '../config';

const { DOMAIN, protocol, port } = config;
const styles = {
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
};

const Devices = (props) => {
  const { user } = props;
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState('');
  const [addResult, setAddResult] = useState(null);
  const [addError, setAddError] = useState(null);
  const [err] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [pinging, setPinging] = useState(false);

  const url = `/users/${user.id}/devices?orderBy=created_at`;

  const { data, loading, mutate } = useSWR(url);

  const columns = [
    { field: 'id',
      hideable: true,
      width: 1,
      renderCell: () => {
        return '';
      },
    },
    {
      field: 'avatar',
      headerName: 'Avatar',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        return <Avatar name={params.row.name + DOMAIN} style={{ margin: '5px', height: '75px', width: '75px' }} />;
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      align: 'left',
      width: 300,
      renderCell: (params) => {
        return `${params.row.name}.${DOMAIN}`;
      },
    },
    {
      field: 'created_at',
      headerName: 'Created',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
      },
    },
    {
      field: 'pass',
      headerName: 'Password',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={() => {
              setSelectedDevice(params.row);
            }}
          >reset</Button>
        );
      },
    },
    {
      field: 'admin',
      headerName: 'Admin',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            onClick={async () => {
              try {
                const url = `/users/${user.id}/devices/${params.row.id}/preauth`;
                const res = await apiFetch.post(url, {});
                const devUrl = `${protocol}://${params.row.name}.${DOMAIN}${port ? ':' + port : ''}/_hs/admin`;
                window.open(devUrl + '?token=' + res.jwt, '_blank').focus();
                console.log('res', res);
              } catch (e) {
                console.log(e);
              }
            }}
          >admin</Button>
        );
      }
    },
    {
      field: 'ping',
      headerName: 'Ping',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        const { row } = params;
        return (
          <div style={{ nothing: { pinging }}}>
            <Button
              variant="contained"
              onClick={async () => {
                try {
                  const url = `/users/${user.id}/devices/${row.id}/srpc`;
                  row.pingStatus = 'pinging';
                  mutate(data.slice());
                  setPinging(true);
                  const res = await apiFetch.post(url, {
                    method: 'ping',
                    params: ['hello'],
                  });
                  row.pingStatus = 'ok';
                  console.log('res', res);
                  mutate(data.slice());
                  setPinging(false);
                } catch (e) {
                  row.pingStatus = 'error';
                  console.log(e);
                  mutate(data.slice());
                  setPinging(false);
                }
              }}
            >ping</Button>
            <span style={{ marginLeft: '5px' }}>
              {row.pingStatus === 'pinging' ? '...' : ''}
              {row.pingStatus === 'ok' ? <span style={{ color: 'green' }}>ok</span> : ''}
              {row.pingStatus === 'error' ? <span style={{ color: 'red' }}>error</span> : ''}
            </span>
          </div>
        );
      }
    },
  ];
  // console.log('data', data);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = () => {
    setSnackbarOpen(true);
  };

  async function copyText(text) {
    try {
      await navigator.clipboard.writeText(text);
      handleSnackbarOpen();
    } catch (e) {
      // eslint-disable-next-line
      console.error('error copying text', e);
    }
  }

  return (
    <div style={styles.container}>
      <div style={styles.mainBody}>
        <Typography variant="h4">
          My Connections
        </Typography>
        <div>{err?.toString()}</div>
        <Paper style={styles.tintedBackground}>
          {!loading ? (
            <div style={styles.gridContainer}>
              <DataGrid columns={columns} rows={data || []} rowHeight={80}/>
            </div>
          ) : <CircularProgress/>}
        </Paper>
        <div style={styles.btnContainer}>
          {!showForm ? (<Button
            variant="contained"
            onClick={() => setShowForm(true)}
          >
            Add Connection
          </Button>) : ''}
          {showForm ? (
            <>
            {addResult ? (
              <div style={{ display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h5">Device Added</Typography>
                <Typography variant="h7">Device name: https://{addResult.name}.{DOMAIN}</Typography>
                <Tooltip title="Copy">
                  <CopyIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      copyText(`https://${addResult.name}.${DOMAIN}`);
                      setSnackbarOpen(true);
                    }}
                  />
                </Tooltip>
                <div>
                  <div>
                    <Typography variant="h7">Device password: {addResult.rawPassword}</Typography>
                    <Tooltip title="Copy">
                      <CopyIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          copyText(addResult.rawPassword);
                          setSnackbarOpen(true);
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div>
                    <Avatar
                      name={addResult.name + DOMAIN}
                      style={{ margin: '5px', width: '200px', height: '200px' }}
                    />
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowForm(false);
                        setName('');
                        setAddResult(null);
                      }}
                    >Close</Button>
                  </div>
                </div>
              </div>
            ) : (
              <div style={{ display: 'flex' }}>
                <div>
                  <div>
                  <Typography variant="h5">Add Device</Typography>
                  <Typography variant="h7">At lease 8 characters.  Alphanumeric, dashes allowed</Typography>
                  {addError ? (
                    <div>
                      <Typography variant="h7" style={{ color: 'red' }}>{addError.toString()}</Typography>
                    </div>
                  ) : ''}
                  </div>
                  {(name && name.length > 7) ? (
                    <Avatar
                      name={name + DOMAIN}
                      style={{ margin: '5px', width: '200px', height: '200px' }}
                    />
                  ) : ''}
                </div>
                <div>
                  <div>
                    <TextField
                      style={{ margin: '15px' }}
                      label="Device Name"
                      value={name}
                      onChange={(e) => {
                        setName(cleanDeviceName(e.target.value));
                      }}
                    />
                  </div>
                  <div style={{ display: 'flex' }}>
                    <Button
                      variant="contained"
                      style={{ margin: '15px' }}
                      onClick={async () => {
                        try {
                          const result = await apiFetch.post(`/users/${user.id}/devices`, { name });
                          setAddResult(result);
                          const newRows = (data || []).slice();
                          newRows.unshift(result);
                          mutate(newRows);
                        } catch (e) {
                          setAddError(e);
                        }
                      }}
                      disabled={name.length < 8}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      style={{ margin: '15px' }}
                      onClick={() => {
                        setShowForm(false);
                        setName('');
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            )}
            </>
          ) : ''}
        </div>
        <Dialog open={!!selectedDevice}>
          <DialogTitle>Reset Password?</DialogTitle>
          <div style={{ magin: '10px' }}>
            <div style={{ magin: '10px', padding: '10px' }}>
              <Typography variant="h7">
                Device name: 
                <strong>
                  https://{selectedDevice?.name}.{DOMAIN}
                </strong>
              </Typography>
              <Tooltip title="Copy">
                <CopyIcon
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    copyText(`https://${selectedDevice?.name}.${DOMAIN}`); 
                    setSnackbarOpen(true);
                  }}
                />
              </Tooltip>
            </div>
            <div>
              <Avatar
                name={selectedDevice?.name + DOMAIN}
                style={{ margin: '5px', width: '200px', height: '200px' }}
              />
            </div>
            {newPassword ? (
              <div style={{ padding: '10px' }}>
                <div>
                  <Typography variant="h7">New password:</Typography>
                </div>
                <div style={{ padding: '10px' }}>
                  {newPassword}
                  <Tooltip title="Copy">
                    <CopyIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        copyText(newPassword); 
                        setSnackbarOpen(true);
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
            ) : ''}
            <div>
              {!newPassword ? (
                <div>
                  <Button
                    onClick={async (e) => {
                      e.preventDefault();
                      try {
                        const eUrl = `/users/${user.id}/devices/${selectedDevice?.id}/password`;
                        const newPass = await apiFetch.put(eUrl, {});
                        setNewPassword(newPass.newPassword);
                      } catch (e) {
                        console.log(e);
                      }
                      // setSelectedDevice(null);
                    }}
                    style={{ margin: '10px' }}
                  >
                    Ok
                  </Button>
                  <Button
                    onClick={() => {
                      setSelectedDevice(null);
                    }}
                    style={{ margin: '10px' }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : (
                <Button
                  onClick={() => {
                    setSelectedDevice(null);
                    setNewPassword(null);
                  }}
                  style={{ margin: '10px' }}
                >Close</Button>
              )}
            </div>
          </div>
        </Dialog>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="Copied to clipboard"
          action={(
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackbarClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default Devices;
