import { useRef, useEffect } from 'react';
import md5 from 'blueimp-md5';

const offScreenCanvas = document.createElement('canvas');
offScreenCanvas.width = 300;
offScreenCanvas.height = 300;
const offScreenContext = offScreenCanvas.getContext('2d');

const fromHexString = (hexString) => {
  return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
}

// const toHexString = (bytes) => {
//   return bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, '0'), '');
// }

// function render(input) {
//   renderHash(md5(input || ''));
// }

// function renderHash(hash) {
//   if(!hash && hash.length < 32) {
//     hash = md5(hash || '');
//   }
//   //strip uuid dashes
//   hash = hash.replace(/\-/g, '');
//   renderBuckets(getBuckets(hash));
// }


function renderAvatar({name, canvas, showBG = true, showShadow = true }) {
  const img = document.getElementById('roboImg');
  const hash = md5(name || '');
  const bucketObj = getBuckets(hash);
  renderBuckets({bucketObj, canvas, img, showBG, showShadow});
}

function getBuckets(hash) {
  // var b = new Buffer(hash, 'hex');
  const b = fromHexString(hash);
  // console.log('b', b);
  const pairs = b.reduce((result, value, key) => {
    if(key % 2) {
      result.push([b[key-1], b[key]]);
      return result;
    }
    return result;
  }, []);
  // console.log('pairs', pairs);
  const buckets = pairs.map((val) => {
    // console.log('vals', val, (val[0] << 8), val[1], (val[0] << 8) + val[1]);
    return ((val[0] << 8) + val[1]) % 10;
  });

  const b1r = (b[0] >> 2) + 192;
  const b1g = (b[1] >> 2) + 192;
  const b1b = (b[2] >> 2) + 192;
  const b2r = (b[3] >> 2) + 192;
  const b2g = (b[4] >> 2) + 192;
  const b2b = (b[5] >> 2) + 192;
  const sr = (b[6] >> 2);
  const sg = (b[7] >> 2);
  const sb = (b[8] >> 2);
  const shadowSize = (b[9] >> 5);
  return {
    buckets,
    b1r,
    b1g,
    b1b,
    b2r,
    b2g,
    b2b,
    sr,
    sg,
    sb,
    shadowSize,
  };
}

function renderBuckets({bucketObj, canvas, img, showBG, showShadow}) {
  const context = canvas.getContext('2d');
  const {
    buckets,
    b1r,
    b1g,
    b1b,
    b2r,
    b2g,
    b2b,
    sr,
    sg,
    sb,
    shadowSize
  } = bucketObj;
  // console.log('buckets', buckets, shadowSize);

  const bodyStyle = buckets[0];
  const headStyle = buckets[1];
  const eyeStyle = buckets[2];
  const mouthStyle = buckets[3];
  const accStyle = buckets[4];
  const bhColor = buckets[5];
  const emColor = buckets[6];
  const accColor = buckets[7];


  if(img) {

    context.shadowColor = null;
    context.shadowOffsetX = 0;
    context.shadowOffsetY = 0;
    context.shadowBlur = null;

    context.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);

    if (showBG) {
      const gColor1 = `rgb(${b1r},${b1g},${b1b})`;
      const gColor2 = `rgb(${b2r},${b2g},${b2b})`;
      const gradient = context.createLinearGradient(0, 0, canvas.width, canvas.height);
      gradient.addColorStop(0, gColor1);
      gradient.addColorStop(1, gColor2);
      // context.clearRect(0, 0, canvas.width, canvas.height);
      context.fillStyle = gradient
      context.fillRect(0, 0, canvas.width, canvas.height);
    }

    offScreenContext.clearRect(0, 0, offScreenCanvas.width, offScreenCanvas.height);
    offScreenContext.drawImage(img, 300 * bodyStyle, 1500 * bhColor + 900, 300, 300, 0, 0, 300, 300);
    offScreenContext.drawImage(img, 300 * headStyle, 1500 * bhColor + 1200, 300, 300, 0, 0, 300, 300);
    offScreenContext.drawImage(img, 300 * mouthStyle, 1500 * emColor, 300, 300, 0, 0, 300, 300);
    offScreenContext.drawImage(img, 300 * eyeStyle, 1500 * emColor + 300, 300, 300, 0, 0, 300, 300);
    offScreenContext.drawImage(img, 300 * accStyle, 1500 * accColor + 600, 300, 300, 0, 0, 300, 300);

    if (showShadow) {
      context.shadowColor = `rgb( ${sr}, ${sg}, ${sb} )`;
      context.shadowOffsetX = 0;
      context.shadowOffsetY = 0;
      context.shadowBlur = 10 + shadowSize;
    }

    context.drawImage(offScreenCanvas, 0, 0, canvas.width, canvas.height);
  }
}


export default function Avatar({ style = {}, name }) {
  const canvasRef = useRef(null);
  const fullStyle = {
    height: '50px',
    width: '50px',
    ...style,
  };

  useEffect(() => {
    // console.log('canvasRef', canvasRef, name);
    const canvas = canvasRef.current;
    // const context = canvas.getContext('2d');
    renderAvatar({name, canvas});
    // context.fillStyle = 'red';
    // context.fillRect(0, 0, 300, 300);
  }, [name]);

  return (
    <canvas
      style={fullStyle}
      ref={canvasRef}
      width={300}
      height={300}
    />
  );
}