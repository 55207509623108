
export const colors = {
  // Style Guide Colors
  primaryColor: '#1d428a', // blue
  primaryAccent: '#17346e', // dark blue
  primaryHighlight: '#4a67a1', // light blue
  primaryText: '#f1f1e6', // light grey
  primaryTextAccent: '#90908a', // darker grey
  primaryTextHighlight: '#f6f6f0', // lighter grey
  secondaryColor: '#a7400e', // burnt red-orange
  secondaryAccent: '#85330b', // burnt..er red-orange
  // eslint-disable-next-line max-len
  gold: 'linear-gradient(315deg, #FACE41, #F9DE89, #FFF8C8, #E8D08F, #D7B565, #C39331, #D7B565, #E8D08F, #FFF8C8)',
  // eslint-disable-next-line max-len
  goldAccent: 'linear-gradient(315deg, #c8a434, #c7b16d, #ccc6a0, #b9a672, #ac9050, #9c7527, #ac9050, #b9a672, #ccc6a0)',
  // eslint-disable-next-line max-len
  goldHighlight: 'linear-gradient(315deg, #fbd766, #fae4a0, #fff9d3, #ecd9a5, #dfc383, #cfa85a, #dfc383, #ecd9a5, #fff9d3)',
  darkAccent: '#3c3d3f',
  highlightBright: '#edeef0',
  highlightMedium: '#b8c5d0',
  highlightDark: '#9da4ad',
  highlightDarker: '#3c4c65',
  successGreen: '#008000',
  errorRed: '#dc3636',
  white: '#ffffff',
  black: '#000000',
  blackOpacity: 'rgba(0, 0, 0, 0.3)', // 80% opacity true black
};

export const goldButtonStyling = {
  background: colors.gold,
  color: colors.black,
  '&:hover': {
    background: colors.goldHighlight,
    color: colors.black,
  },
};

export const formButton = {
  margin: '5px',
};
